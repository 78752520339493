import { useState, createContext } from "react";

export const ConfigContext = createContext();

export const ConfigProvider = (props) => {
  const [configState, setConfigState] = useState({
    REACT_APP_AUTH0_AUDIENCE: null,
    REACT_APP_AUTH0_DOMAIN: null,
    REACT_APP_AUTH0_CLIENT_ID: null,
    REACT_APP_BACKEND_URL: "localhost:5000",
    AUTH0_TOKEN: null,
    JIRA_BASE_URL: null,
  });

  return (
    <ConfigContext.Provider value={[configState, setConfigState]}>
      {props.children}
    </ConfigContext.Provider>
  );
};
