import React from "react";
import Moment from "react-moment";
import moment from "moment";
import timeDiffColor from "../../utils/timeDiffColor";
import { Chip, Typography, Box } from "@mui/material";

export const customerInformationColumns = ({ calculatedTotals }) => [
  {
    header: "Env",
    accessorKey: "environment",
  },
  {
    header: "Region",
    accessorKey: "region",
  },
  {
    header: "Cluster",
    accessorKey: "cluster",
  },
  {
    header: "Customer",
    accessorKey: "customer",
    Cell: ({ row }) => (
      <a
        id={row.id}
        href={`https://central-manager.${row.original.customer}.pcm.pricer-plaza.com`}
        target="_blank"
        rel="noreferrer"
      >
        {row.original.customer}
      </a>
    ),
  },
  {
    header: "Dataload Customer",
    accessorKey: "is_active_dataload_customer",
  },
  {
    header: "PCS Version",
    accessorKey: "pcs_version",
  },
  {
    header: "CM Version",
    accessorKey: "central_manager_version",
  },
  {
    header: "StoreUnit Version",
    accessorKey: "store_unit_version",
  },
  {
    header: "Profile",
    accessorKey: "customer_profile_values.customer_profile",
    accessorFn: (row) => {
      return row.customer_profile_values?.customer_profile ?? "";
    },
  },
  {
    header: "# esls",
    accessorKey: "number_of_esls",
    Header: () => `# esls (${calculatedTotals.number_of_esls})`,
  },
  {
    header: "# 50+ linked labels",
    accessorKey: "number_of_stores_linked_labels_ge_50",
  },
  {
    header: `# stores`,
    accessorKey: "number_of_stores",
    Header: () => `# stores (${calculatedTotals.number_of_stores})`,
  },
  {
    header: "# stores xs",
    accessorKey: "number_of_stores_xs",
    Header: () => `# stores xs (${calculatedTotals.number_of_stores_xs})`,
  },
  {
    header: "# stores s",
    accessorKey: "number_of_stores_s",
    Header: () => `# stores s (${calculatedTotals.number_of_stores_s})`,
  },
  {
    header: "# stores m",
    accessorKey: "number_of_stores_m",
    Header: () => `# stores m (${calculatedTotals.number_of_stores_m})`,
  },
  {
    header: "# stores l",
    accessorKey: "number_of_stores_l",
    Header: () => `# stores l (${calculatedTotals.number_of_stores_l})`,
  },
  {
    header: "# stores xl",
    accessorKey: "number_of_stores_xl",
    Header: () => `# stores xl (${calculatedTotals.number_of_stores_xl})`,
  },
  {
    header: "# ref stores",
    accessorKey: "reference_stores",
    Header: () => `# ref stores (${calculatedTotals.reference_stores})`,
  },
  {
    header: "# stopped stores",
    accessorKey: "number_of_stopped_stores",
    Header: () =>
      `# stopped stores (${calculatedTotals.number_of_stopped_stores})`,
  },
  {
    header: "# items",
    accessorKey: "number_of_items",
    Header: () => `# items (${calculatedTotals.number_of_items})`,
  },
  {
    header: "Store Limit",
    accessorKey: "store_limit",
  },
  {
    header: "# items updated last 24h",
    accessorKey: "prometheus_values.pricer_backoffice_entries_total_1d",
    accessorFn: (row) => {
      if (row.prometheus_values?.pricer_backoffice_entries_total_1d >= 0) {
        return Number(row.prometheus_values.pricer_backoffice_entries_total_1d);
      }
      return;
    },
  },
  {
    header: "item updates/labels ratio",
    id: "updates_labels_ratio",
    accessorFn: (row) => {
      if (row.prometheus_values?.pricer_backoffice_entries_total_1d > 0) {
        return Number(
            row.prometheus_values?.pricer_backoffice_entries_total_1d /
            row.number_of_esls
        );
      }
      return;
    },
  },
  {
    header: "# price pages in last 24h",
    accessorKey: "prometheus_values.pricer_transmitted_price_pages_total_1d",
    accessorFn: (row) => {
      if (row.prometheus_values?.pricer_transmitted_price_pages_total_1d >= 0) {
        return Number(
          row.prometheus_values.pricer_transmitted_price_pages_total_1d,
        );
      }
      return;
    },
  },
  {
    header: "# price pages in last 30 days",
    accessorKey: "prometheus_values.pricer_transmitted_price_pages_total_30d",
    accessorFn: (row) => {
      if (
        row.prometheus_values?.pricer_transmitted_price_pages_total_30d >= 0
      ) {
        return Number(
          row.prometheus_values.pricer_transmitted_price_pages_total_30d,
        );
      }
      return;
    },
  },
  {
    header: "# info pages in last 24h",
    accessorKey: "prometheus_values.pricer_transmitted_info_pages_total_1d",
    accessorFn: (row) => {
      if (row.prometheus_values?.pricer_transmitted_info_pages_total_1d >= 0) {
        return Number(
          row.prometheus_values.pricer_transmitted_info_pages_total_1d,
        );
      }
      return;
    },
  },
  {
    header: "# info pages in last 30 days",
    accessorKey: "prometheus_values.pricer_transmitted_info_pages_total_30d",
    accessorFn: (row) => {
      if (row.prometheus_values?.pricer_transmitted_info_pages_total_30d >= 0) {
        return Number(
          row.prometheus_values.pricer_transmitted_info_pages_total_30d,
        );
      }
      return;
    },
  },
  {
    header: "# flash in last 24h",
    accessorKey: "prometheus_values.pricer_label_flash_led_on_counter_total_1d",
    accessorFn: (row) => {
      if (
        row.prometheus_values?.pricer_label_flash_led_on_counter_total_1d >= 0
      ) {
        return Number(
          row.prometheus_values.pricer_label_flash_led_on_counter_total_1d,
        );
      }
      return;
    },
  },
  {
    header: "# flash in last 30 days",
    accessorKey:
      "prometheus_values.pricer_label_flash_led_on_counter_total_30d",
    accessorFn: (row) => {
      if (
        row.prometheus_values?.pricer_label_flash_led_on_counter_total_30d >= 0
      ) {
        return Number(
          row.prometheus_values.pricer_label_flash_led_on_counter_total_30d,
        );
      }
      return;
    },
  },
  {
    header: "Shared DB",
    accessorKey: "customer_profile_values.use_common_db",
    accessorFn: (row) => {
      return row.customer_profile_values?.use_common_db ?? "";
    },
    Cell: ({ row }) => {
      const useCommonDB = row.original.customer_profile_values?.use_common_db;
      if (useCommonDB === true) {
        return <Chip label="True" size="small" color="success" />;
      } else {
        return <Chip label="False" size="small" color="error" />;
      }
    },
  },
  {
    header: "PCM Usecases",
    accessorKey: "values.pcm_usecases",
    Cell: ({ row }) => {
      const pcmUsecases = row.original.values.pcm_usecases;
      if (Array.isArray(pcmUsecases) && pcmUsecases.length > 0) {
        return pcmUsecases.map((usecase) => (
          <Chip
            key={usecase}
            label={usecase}
            size="small"
            color="primary"
            sx={{ margin: 0.2 }}
          />
        ));
      }
      return null;
    },
  },
  {
    header: "PCS License Profile Type",
    accessorKey: "customer_profile_values.pcs_license_profile_type",
    accessorFn: (row) => {
      return row.customer_profile_values?.pcs_license_profile_type ?? "";
    },
    Cell: ({ row }) =>
      row.original.customer_profile_values?.pcs_license_profile_type?.toLowerCase() ||
      "",
    filterFn: "equals",
  },
  {
    header: "Created at",
    sortingFn: "datetime",
    accessorKey: "timestamp",
    Cell: ({ row }) => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" component="body2" sx={{ fontSize: "12px" }}>
          {row.original.timestamp}
        </Typography>

        <Typography
          variant="body3"
          component="body3"
          color={timeDiffColor(
            row.original.timestamp,
            "YYYY-MM-DD",
            "months",
            -1,
          )}
          sx={{ fontSize: "10px" }}
        >
          <Moment fromNow>
            {moment(row.original.timestamp, "YYYY-MM-DD")}
          </Moment>
        </Typography>
      </Box>
    ),
  },
  {
    header: "Access codes",
    id: "centralmanager_tenant_derived_access_codes_enable",
    accessorFn: (row) => {
      return row.values?.centralmanager_tenant_derived_access_codes_enable ? "True" : "False" ?? "False";
    },
    Cell: ({ row }) => {
      const isAccessCodesEnabled = row.original.values?.centralmanager_tenant_derived_access_codes_enable;
      if (isAccessCodesEnabled) {
        return <Chip label="True" size="small" color="success" />;
      } else {
        return <Chip label="False" size="small" color="error" />;
      }
    },
  },
  {
    header: "Auto unlink",
    id: "centralmanager_enable_access_code_rotation_with_forced_auto_unlink",
    accessorFn: (row) => {
      return row.values?.centralmanager_enable_access_code_rotation_with_forced_auto_unlink ? "True" : "False" ?? "False";
    },
    Cell: ({ row }) => {
      const isAutoUnlinkEnabled = row.original.values?.centralmanager_enable_access_code_rotation_with_forced_auto_unlink;
      if (isAutoUnlinkEnabled) {
        return <Chip label="True" size="small" color="success" />;
      } else {
        return <Chip label="False" size="small" color="error" />;
      }
    },
  },
];
